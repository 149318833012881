p:has(img) {
    display: flex;
}
p:has(img.alignright) {
    flex-direction: row-reverse;
}
img.alignleft {
    margin-right: 1em;
}
img.alignright {
    margin-left: 1em;
}
